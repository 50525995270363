<script>
import FormModalMixin from '../../../../../share/modal/FormModalMixin'
import FormModal from '../form/UserDataEdition'
import api from '../../../../../../api'

export default {
  components: {
    FormModal
  },
  mixins: [
    FormModalMixin
  ],
  data () {
    return {
      title: this.$t('pages.user.details.usersDataEdition'),
      okText: this.$t('pages.user.details.usersDataEditOkButton')
    }
  },
  methods: {
    submitForm () {
      api.request('core', 'put', `users/${this.$route.params.uuid}`, this.createDataToSend())
        .then(() => {
          this.toggleLoading()
          this.$emit('success', this.model)
          this.$emit('close')
          this.$notify({
            type: 'success',
            text: this.$t('pages.user.details.usersDataEditionSuccesful')
          })
        })
        .catch((error) => {
          this.toggleLoading()
          this.errorNotify(error)
        })
    },
    createDataToSend () {
      return {
        forename: this.model.forename,
        surname: this.model.surname,
        phone: this.model.phone
      }
    }
  }
}
</script>
