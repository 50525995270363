<template>
  <form>
    <div
      class="modal-body form-group required-field"
      :class="[{ 'has-error': vErrors.first('units'), 'has-success' : !vErrors.first('units') && checkboxHasBeenChanged }]"
    >
      <label>Jednostki:</label>
      <ul>
        <template
          v-for="(option, index) in value"
        >
          <li
            :key="index"
            class="checkbox"
          >
            <div
              :key="index"
              class="form-checkbox"
            >
              <input
                :id="`change-unit-${index}`"
                v-model="value[index].value"
                type="checkbox"
                @change="$emit('input', value)"
              >
              <label :for="`change-unit-${index}`">{{ option.label }}</label>
            </div>
          </li>
        </template>
      </ul>
    </div>
  </form>
</template>

<script>
import FormModalValidation from '../../../../share/form/FormModalValidation'

export default {
  mixins: [
    FormModalValidation
  ],
  props: {
    value: {}
  },
  data () {
    return {
      checkboxHasBeenChanged: false
    }
  }
}
</script>
