<template>
  <base-form-modal
    :show="show"
    :title="title"
    :ok-text="okText"
    :cancel-text="cancelText"

    @show="open"
    @close="$emit('close')"
    @beforeSubmit="function (bool) {beforeSubmitTrigger = bool}"
  >

    <form-modal
      :is-before-submit="beforeSubmitTrigger"
      :service="service"
      v-model="model"
      :show="show"
      @addRow="addRow"
      @removeRow="removeRow"
      ref="form"
    >
    </form-modal>
  </base-form-modal>
</template>
<script>
  import FormModalMixin from '../../../../../share/modal/FormModalMixin'
  import FormModal from '../form/IpFilters'
  import api from '../../../../../../api'
  import _ from 'lodash'

  export default {
    components: {
      FormModal
    },
    props: {
      formData: { default: () => [] },
    },
    mixins: [
      FormModalMixin
    ],
    data () {
      return {
        title: 'Edycja filtrów IP',
        okText: 'Zmień',
        model: []
      }
    },
    mounted () {
      this.model = _.cloneDeep(this.formData)
    },
    methods: {
      submitForm () {
        api.request('core', 'put', `/users/${this.$route.params.uuid}/access-ips`, this.createDataToSend())
          .then(() => {
            this.toggleLoading()

            this.$emit('success', this.model)
            this.$emit('close')
            this.$notify({
              type: 'success',
              text: 'Zaktualizowano dane filtrów IP'
            })
          })
          .catch((error) => {
            this.toggleLoading()
            this.errorNotify(error)
          })
      },
      addRow () {
        this.model.push([])
      },
      removeRow (indexToDelete) {
        this.model = this.model.filter((row, index) => index !== indexToDelete)
      },
      createDataToSend () {
        return this.model.map(ip => ip.filter(ip => ip)).filter(ip => ip.length !== 0).map(ip => ip.join('.'))
      }
    }
  }
</script>
