<template>
  <base-form-modal
    :show="show"
    :title="title"
    :ok-text="okText"
    :cancel-text="cancelText"

    @show="open"
    @close="$emit('close')"
    @beforeSubmit="function (bool) {beforeSubmitTrigger = bool}"
  >
    <form-modal
      ref="form"
      v-model="model"
      :user="user"
      :is-before-submit="beforeSubmitTrigger"
      :service="service"
    />
  </base-form-modal>
</template>

<script>
import FormModalMixin from '../../../../../share/modal/FormModalMixin'
import FormModal from '../form/AbsenceEdition'
import api from '../../../../../../api'

export default {
  components: {
    FormModal
  },
  mixins: [
    FormModalMixin
  ],
  props: {
    user: { type: Object }
  },
  data () {
    return {
      title: 'Nieobecność',
      okText: 'Zmień',
      model: {
        substitute: null,
        content: ''
      }
    }
  },
  watch: {
    'model.substitute': function (newVal, oldVal) {
      if (newVal === '' || newVal === null) {
        this.model.redirectToSubstitute = false
        this.model.sendCopyToAbsent = false
      }
    },
    'model.redirectToSubstitute': function (newVal, oldVal) {
      if (newVal === false) {
        this.model.sendCopyToAbsent = false
      }
    }
  },
  methods: {
    submitForm () {
      api.request('core', 'put', `users/${this.$route.params.uuid}/absence`, this.createDataToSend())
        .then(() => {
          this.toggleLoading()

          this.$emit('success', this.model)
          this.$emit('close')
          this.$notify({
            type: 'success',
            text: 'Zaktualizowano dane'
          })
        })
        .catch((error) => {
          this.toggleLoading()
          this.errorNotify(error)
        })
    },
    createDataToSend () {
      return {
        user: this.$route.params.uuid,
        startAt: this.model.startAt,
        endAt: this.model.endAt,
        substitute: this.model.substitute,
        redirectToSubstitute: this.model.redirectToSubstitute,
        sendCopyToAbsent: this.model.sendCopyToAbsent,
        extraRecipientEmail: this.model.extraRecipientEmail,
        content: this.model.content
      }
    }
  }
}
</script>
