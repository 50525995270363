<script>
  import FormModalMixin from '../../../../../share/modal/FormModalMixin'
  import FormModal from '../form/UserAdditionalDataEdition'
  import api from '../../../../../../api'

  export default {
    components: {
      FormModal
    },
    mixins: [
      FormModalMixin
    ],
    data () {
      return {
        title: 'Edycja danych użytkownika',
        okText: 'Zmień'
      }
    },
    methods: {
      async submitForm () {
        try {
          this.toggleLoading()
          await api.request('core', 'put', `users/${this.$route.params.uuid}/config`, this.createDataToSend())
          this.$notify({
            type: 'success',
            text: 'Zaktualizowano dane użytkownika'
          })
        } catch (e) {
          this.toggleLoading()
          this.errorNotify(e)
        }
        await this.sendToFinance()
      },
      createDataToSend () {
        return {
          description: this.model.description || '',
          entitlementNumber: this.model.entitlementNumber || '',
          isAttachmentPassword: this.model.isAttachmentPassword
        }
      },
      async sendToFinance() {
        try {
          await api.request('finance', 'put', `users/${this.$route.params.uuid}`, this.createDataToSendFinance())
          this.$emit('success', this.model)
          this.$emit('close')
          this.$notify({
            type: 'success',
            text: 'Zaktualizowano pracujące soboty'
          })
        } catch (e) {
          this.$emit('success', this.model)
          this.$emit('close')
          this.toggleLoading()
          this.errorNotify(e)
        }
      },
      createDataToSendFinance () {
        return {
          workingSaturday: this.model.workingSaturday
        }
      },
    }
  }
</script>
