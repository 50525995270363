import { mapActions } from 'vuex'

export default {
  data () {
    return {
      vatEditionVisible: false
    }
  },
  mounted () {
    this.getUsersVat({userUuid: this.$route.params.uuid})
    this.loadVatOptions()
  },
  beforeDestroy() {
    this.$store.commit('CLEAR_USER_VAT')
  },
  methods: {
    ...mapActions([
      'loadVatOptions',
      'getUsersVat'
    ]),
    editVat () {
      this.vatEditionVisible = true
    }
  }
}
