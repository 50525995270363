<script>
  import FormModalMixin from '../../../../../share/modal/FormModalMixin'
  import FormModal from './EditCoinAmountForm'
  import api from '../../../../../../api'

  export default {
    components: {
      FormModal
    },
    mixins: [
      FormModalMixin
    ],
    data () {
      return {
        title: 'Edycja ilości wolnych Żetonów',
        okText: 'Zmień'
      }
    },
    methods: {
      addCoins () {
        let dataToSend = { amount: this.model.amount }

        api.request('extshp', 'put', `/users/${this.$route.params.uuid}/coins`, dataToSend)
          .then((response) => {
            this.$notify({
              type: 'success',
              text: 'Pomyślnie zmieniono ilość Żetonów'
            })
            this.$emit('success')
            this.$emit('close')
          })
          .catch((error) => {
            this.errorNotify(error)
          })
      },
      submitForm () {
        this.addCoins()
      },
    }
  }
</script>
