<template>
  <form>
    <date-picker-type
      ref="startAt"
      v-model="value['startAt']"
      v-validate="{ rules: { required: datesRequired, date_format: 'yyyy-MM-dd', value_less_than_parent: 'endAt' } }"
      name="startAt"
      label="Od"
      :required="datesRequired"
      data-vv-as="Od"
      :error-message="vErrors.first('startAt')"
      @input="validateDatefields"
    />
    <date-picker-type
      ref="endAt"
      v-model="value['endAt']"
      v-validate="{ rules: { required: datesRequired, date_format: 'yyyy-MM-dd', value_greater_than_parent: 'startAt' } }"
      name="endAt"
      label="Do"
      :required="datesRequired"
      data-vv-as="Do"
      :error-message="vErrors.first('endAt')"
      @input="validateDatefields"
    />
    <div class="form-group">
      <select-type
        v-model="value['substitute']"
        v-validate="{rules: {required: false}}"
        name="substitute"
        label="Osoba zastępująca"
        :required="false"
        :options="options"
        :empty-first="true"
        data-vv-as="osoba zastępująca"
        :error-message="vErrors.first('substitute')"
      />
    </div>
    <!--<div class="form-group">-->
    <!--<input-type-->
    <!--name="extraRecipientEmail"-->
    <!--label="Email dodatkowego odbiorcy"-->
    <!--:error-message="vErrors.first('extraRecipientEmail')"-->
    <!--:required="false"-->
    <!--v-model="value['extraRecipientEmail']"-->
    <!--v-validate="{ rules: { email: true }}"-->
    <!--data-vv-value-path="value"-->
    <!--data-vv-as="email dodatkowego odbiorcy"-->
    <!--&gt;-->
    <!--</input-type>-->
    <!--</div>-->
    <div class="form-group checkbox checkbox-flex">
      <div class="form-checkbox">
        <input
          id="redirectToSubstitute"
          v-model="value['redirectToSubstitute']"
          type="checkbox"
          :disabled="value['substitute'] === null || value['substitute'] === ''"
        >
        <label for="redirectToSubstitute" />
      </div>
      <label for="redirectToSubstitute">Przekieruj pocztę do osoby zastępującej</label>
    </div>
    <div class="form-group checkbox checkbox-flex">
      <div class="form-checkbox">
        <input
          id="sendCopy"
          v-model="value['sendCopyToAbsent']"
          type="checkbox"
          :disabled="!value['redirectToSubstitute']"
        >
        <label for="sendCopy" />
      </div>
      <label for="sendCopy">Wyślij kopię do mnie</label>
    </div>
    <div class="form-group">
      <text-area-type
        v-model="value['content']"
        v-validate="{rules: {required: contentRequired}}"
        name="content"
        label="Treść automatycznej wiadomości"
        :required="contentRequired"
        data-vv-as="treść automatycznej wiadomości"
        :error-message="vErrors.first('content')"
        @change="updatePreview"
      />
    </div>
    <div class="panel panel-default">
      <div class="panel-heading">
        Poglądowa treść wiadomości
      </div>
      <div class="panel-body">
        <!--Below code is displayed as autoresponer message preview-->
        <pre>
Ta wiadomość została wygenerowana automatycznie.
{{ contentPreview }}
<div v-if="value.redirectToSubstitute">W sprawach pilnych proszę o kontakt z {{ this.$store.state.base.substitutes[this.value['substitute']]['fullname'] }}, {{ this.$store.state.base.substitutes[this.value['substitute']]['email'] }}. Poczta jest przekierowywana.</div>
--
Z wyrazami szacunku,
{{ `${user.forename} ${user.surname}` }}
            </pre>
      </div>
    </div>
  </form>
</template>

<script>
import api from '../../../../../../api'
import { Validator } from 'vee-validate'
import FormModalValidation from '../../../../../share/form/FormModalValidation'
import SelectType from '../../../../../share/form/type/SelectType'
import DatePickerType from '../../../../../share/form/type/DatePickerType'
import TextAreaType from '../../../../../share/form/type/TextAreaType'

export default {
  components: {
    TextAreaType,
    SelectType,
    DatePickerType
  },
  mixins: [
    FormModalValidation
  ],
  props: {
    value: {
      startAt: null,
      endAt: null,
      substitute: null,
      content: '',
      redirectToSubstitute: false,
      sendCopyToAbsent: false,
      extraRecipientEmail: ''
    },
    user: { type: Object }
  },
  data () {
    return {
      options: [],
      startAt: '',
      endAt: '',
      messages: {
        oneDay: 'W dniu <date> przebywam poza biurem bez dostępu do skrzynki pocztowej.',
        multipleDays: 'W dniach od <from> do <to> przebywam poza biurem bez dostępu do skrzynki pocztowej.'
      },
      contentRequired: false,
      contentPreview: ''
    }
  },
  computed: {
    datesRequired () {
      if (!this.startAt && !this.endAt) {
        return false
      }

      return true
    }
  },
  watch: {
    value: {
      handler: function (newValue, oldValue) {
        this.startAt = newValue['startAt']
        this.endAt = newValue['endAt']
        if (newValue['content'] !== undefined) {
          this.value['content'] = newValue['content']
          this.contentPreview = newValue['content']
        } else {
          newValue['content'] = oldValue['content']
        }
      },
      deep: true
    },
    startAt: {
      handler: function (newValue, oldValue) {
        this.contentRequired = this.datesSelected()
        if (this.contentRequired && newValue !== oldValue) {
          this.changeContent()
        }
      }
    },
    endAt: {
      handler: function (newValue, oldValue) {
        this.contentRequired = this.datesSelected()
        if (this.contentRequired && newValue !== oldValue) {
          this.changeContent()
        }
      }
    }
  },
  created () {
    Validator.extend('value_less_than_parent', {
      getMessage: () => 'Data musi być młodsza.',
      validate: (value, args) => {
        let ref = args[0]

        if (!value || !this.value[ref]) {
          return true
        }

        let dateField = new Date(value)
        let dateParent = new Date(this.value[ref])

        return dateField <= dateParent
      }
    })

    Validator.extend('value_greater_than_parent', {
      getMessage: (field, args) => 'Data musi być starsza.',
      validate: (value, args) => {
        let ref = args[0]

        if (!value || !this.value[ref]) {
          return true
        }

        let dateField = new Date(value)
        let dateParent = new Date(this.value[ref])

        return dateField >= dateParent
      }
    })
  },
  mounted () {
    this.loadSubstitutes()
  },
  methods: {
    validateDatefields () {
      this.$validator.validateAll()
    },
    updatePreview (value) {
      this.contentPreview = value
    },
    datesSelected () {
      return this.startAt !== '' && this.endAt !== ''
    },
    loadSubstitutes () {
      api.request('core', 'get', `users/${this.$route.params.uuid}/substitutes-list`)
        .then((response) => {
          const users = response.data
          this.$store.commit('SET_SUBSTITUTES', users)
          for (let uuid in users) {
            this.options.push({
              value: uuid,
              label: users[uuid]['fullname']
            })
          }
        })
        .catch((error) => {
          this.errorNotify(error)
        })
    },
    changeContent () {
      this.value['content'] = (this.value['startAt'] !== this.value['endAt']) ? this.messages.multipleDays.replace('<from>', this.value['startAt'] ?? '--').replace('<to>', this.value['endAt'] ?? '--') : this.messages.oneDay.replace('<date>', this.value['endAt'] ?? '--')
      this.contentPreview = this.value['content']
      this.$validator.validateAll()
    }
  }
}
</script>

<style scoped>
    .form-group.checkbox.checkbox-flex {
        display: flex;
    }
</style>
