<template>
  <div>
    <span
      v-show="vErrors && vErrors.items && vErrors.items.length > 0"
      class="help-block"
    >
      Wszystkie pola są wymagane
    </span>
    <form @submit.prevent="() => {}">
      <template v-for="(row, rowIndex) in value">
        <div :key="rowIndex" class="ip-table-row">
          <span>{{rowIndex + 1}}.</span>
          <input
            :class="['ip-input', { 'has-error': vErrors.first(`ip-${rowIndex}-field-${fieldIndex}`), 'has-success' : !vErrors.first(`ip-${rowIndex}-field-${fieldIndex}`) && value[rowIndex][fieldIndex] }, { 'required-field': !value[rowIndex][fieldIndex] }]"
            v-for="(field, fieldIndex) in ipModel"
            :key="fieldIndex"
            v-model="value[rowIndex][fieldIndex]"
            :error-message="vErrors.first(`ip-${rowIndex}-field-${fieldIndex}`)"
            :label="''"
            :name="`ip-${rowIndex}-field-${fieldIndex}`"
            v-validate="{rules: {required: true}}"
          />
          <i class="feather-minus-circle" @click="() => $emit('removeRow', rowIndex)"></i>
        </div>
      </template>
    </form>
    <div class="ip-table-add-button">
      <button class="btn zord-btn btn-primary zord-btn-primary" @click.prevent="() => $emit('addRow')">dodaj</button>
    </div>
  </div>
</template>

<script>
  import FormModalValidation from '../../../../../share/form/FormModalValidation'

  export default {
    components: {
    },
    mixins: [
      FormModalValidation
    ],
    props: {
      value: {default: () => []}
    },
    data () {
      return {
        ipModel: [0,0,0,0],
        rules: { regex: /(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?){3,}$/ }
      }
    }
  }
</script>
<style scoped>
  .ip-table-row {
    line-height: 3rem;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    height: 3rem;
  }
  .ip-table-row input {
    width: 20%;
  }
  .ip-table-row i {
    font-size: 3rem;
  }
  .ip-table-add-button {
    margin-left: 3rem;
    margin-right: 3rem;
    display: flex;
    justify-content: flex-end;
  }
  .ip-input {
    margin: 2px;
  }
  .required-field {
    border: 1px red solid;
    border-radius: 2px;
  }
  .has-error {
    border: 1px red solid;
    border-radius: 2px;
  }
  .has-success {
    border: 1px  #6ac13b solid;
    border-radius: 2px;
  }

  .help-block {
    display: flex;
    justify-content: center;
    color: red
  }
</style>
