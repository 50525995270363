<template>
  <form>
    <div
      class="modal-body form-group required-field"
      :class="[{ 'has-error': vErrors.first('contractorId'), 'has-success' : !vErrors.first('contractorId') && contractorSelectHasBeenChanged }]"
    >
      <label>Kontrahent:</label>
      <select
        v-model="selected"
        name="contractorId"
        class="form-control"
        @change="selectValue($event.target.selectedOptions)"
      >
        <option
          v-for="(option, index) in contractors"
          :key="index"
          :value="option.id"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
  </form>
</template>

<script>
import FormModalValidation from '../../../../share/form/FormModalValidation'
import api from '../../../../../api'

export default {
  mixins: [
    FormModalValidation
  ],
  props: {
    value: {}
  },
  data () {
    return {
      contractorSelectHasBeenChanged: false,
      contractors: []
    }
  },
  computed: {
    selected: {
      get: function () {
        return (this.value !== null && this.value.hasOwnProperty('id')) ? this.value['id'] : null
      },
      set: function (value) {
        return value
      }
    }
  },
  mounted () {
    this.loadContractors()
  },
  methods: {
    loadContractors () {
      api.request('core', 'get', '/contractors')
        .then((response) => {
          const sortAlphaNum = (a, b) => a.label.localeCompare(b.label, 'pl', { numeric: true })
          this.contractors = response.data.sort(sortAlphaNum)
        })
        .catch((error) => {
          this.errorNotify(error)
        })
    },
    selectValue (selectedOptions) {
      let result = 0
      for (let c = 0; c < this.contractors.length; c++) {
        if (this.contractors[c].id === parseInt(selectedOptions[0].value)) {
          result = c
          break
        }
      }
      this.$events.$emit('core:user:modalContractor', this.contractors[result])
      this.contractorSelectHasBeenChanged = true
    }
  }
}
</script>
