<template>
  <form>
    <template
      v-for="field in schema"
    >
      <input-type
        v-if="!field.hasOwnProperty('hidden') || (field.hasOwnProperty('hidden') && !field.hidden)"
        :key="field.name"
        v-model="value[field.name]"
        v-validate="{ rules: field.rules }"
        :data-vv-value-path="'value'"
        :data-vv-as="field.label2 ? field.label2 : field.label"
        :error-message="vErrors.first(field.name)"
        :label="field.label"
        :name="field.name"
        :required="field.rules.required"
      />
    </template>
  </form>
</template>

<script>
import FormModalValidation from '../../../../../share/form/FormModalValidation'
import InputType from '../../../../../share/form/type/InputType'

export default {
  components: {
    InputType
  },
  mixins: [
    FormModalValidation
  ],
  props: {
    value: {}
  },
  data () {
    return {
      schema: [
        { name: 'forename', label: this.$t('pages.users.details.forename'), rules: { required: true, regex: /^[a-zA-ZęóąśłżźćńÓŚŁŃŻŹĆ]{3,}$/ } },
        // eslint-disable-next-line no-useless-escape
        { name: 'surname', label: this.$t('pages.users.details.surname'), rules: { required: true, regex: /^[a-zA-ZęóąśłżźćńÓŚŁŃŻŹĆ\-]{3,}$/ } },
        {
          name: 'phone',
          label:  this.$t('pages.users.details.phone'),
          label2: this.$t('pages.users.details.phone'),
          rules: { required: false, regex: /^(\++\d{2}\s)?((\d{2}\s)+\d{7}|\d{9})$/ }
        }
      ]
    }
  }
}
</script>
<style>
  .user-details-checkbox-container {

  }
</style>
