<template>
  <form id="priceListForm" >
    <p class="pricelist-title">Wszystkie podane kwoty są kwotami netto</p>
      <div class="pricelist-wrapper" v-for="field in prices" :key="field.name">
        <label :for="field.name">
          {{field.label}}
        </label>
        <div class="input-wrapper">
          <input
            v-model="value[field.name]"
            :id="field.name"
            :maxlength="max"
            type="text"
            class="form-control input-value"
            @keypress="numbersOnly"
          />
          <span class="input-currency">zł</span>
        </div>
      </div>
  </form>
</template>

<script>
  import FormModalValidation from '../../../../../share/form/FormModalValidation';

  export default {
    name: 'PricelistForm',
    mixins: [
      FormModalValidation
    ],
    props: {
      value: {type: Object, required: false}
    },
    data () {
      return {
        prices: [
          { name: 'residentialFinished', label: 'Lokal gotowy' },
          { name: 'residentialInProgress', label: 'Lokal w budowie' },
          { name: 'buildingFinished', label: 'Budynek gotowy' },
          { name: 'buildingInProgress', label: 'Budynek w budowie' },
          { name: 'plot', label: 'Grunt' },
        ],
        max: 3,
      }
    },
    methods: {
      numbersOnly(event) {
        if (!['0','1','2','3','4','5','6','7','8','9'].includes(event.key)) {
          event.preventDefault();
        } else {
          return true;
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .pricelist-title {
    color: $mainColor;
    line-height: 2rem;
    padding: 0.5rem 0;
    margin-bottom: 1.5rem;
  }

  .pricelist-wrapper {
    margin-bottom: 1.5rem;
  }

  .input-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .input-value {
    text-align: end;
  }

  .input-currency{
    margin-left: 4px;
  }
</style>
