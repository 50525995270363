<template>
  <div>
    <section class="content">
      <div class="row">
        <div class="col-sm-12">
          <div class="box box-default box-solid box-component">
            <div class="box-body box-details-header">
              <div class="details-header">
                <div class="details-header--avatar">
                  <div id="imgframe">
                    <img id="avatarImage" class="img-responsive img-thumbnail" />
                  </div>
                </div>
                <div class="details-header--details details-header--details-custom">
                  <h2>{{$t('pages.user.details.personalData')}}</h2>
                  <ul class="list-unstyled">
                    <li>
                      <label>{{$t("pages.users.details.forename")}}:</label>
                      {{ user.forename }}
                    </li>
                    <li>
                      <label>{{$t("pages.users.details.surname")}}:</label>
                      {{ user.surname }}
                    </li>
                    <li>
                      <label>{{$t("pages.users.details.phone")}}:</label>
                      {{ user.phone }}
                    </li>
                    <li>
                      <label>{{$t("pages.users.details.email")}}:</label>
                      {{ user.email }}
                    </li>
                    <li>
                      <label>{{$t("pages.users.details.entitlementNumber")}}:</label>
                      {{ user.entitlementNumber }}
                    </li>
                    <li v-if="$store.state.base.user.roles.includes('ROLE_SUPER_ADMIN')">
                      <label>Opis:</label>
                      {{ user.description }}
                    </li>
                    <li>
                      <label>{{$t("pages.users.details.financialMode")}}:</label>
                      {{ getVatToShow }}
                    </li>
                  </ul>
                </div>
                <div class="details-header--details details-header--details-custom">
                  <h2>{{$t('pages.user.details.contractor')}}</h2>
                  <ul class="list-unstyled">
                    <li>
                      <label>{{$t("pages.users.details.contractorName")}}:</label>
                      {{ user.contractor !== null ? user.contractor.label : '' }}
                    </li>
                    <li>
                      <label>{{$t("pages.users.details.contractorDesctiption")}}:</label>
                      {{ user.contractor !== null ? user.contractor.description : '' }}
                    </li>
                    <li>
                      <label>{{$t("pages.users.details.phone")}}:</label>
                      {{ user.contractor !== null ? user.contractor.phone : '' }}
                    </li>
                    <li>
                      <label>{{$t("pages.users.details.email")}}:</label>
                      {{ user.contractor !== null ? user.contractor.email : '' }}
                    </li>
                  </ul>
                </div>
                <div class="details-header--details details-header--details-custom">
                  <h2>{{$t('pages.user.details.units')}}</h2>
                  <ul class="list-unstyled">
                    <li v-for="(unit, index) in user.units" :key="index">{{ unit.label }}</li>
                  </ul>

                  <Ips
                    :ips="allIps"
                    v-if="$auth.isAllowed({service: 'core', action: 'get_user_access_ips'})"
                    style="margin: 0px"
                  />
                </div>
                <div
                  v-if="absenceVisible"
                  class="details-header--details details-header--details-custom"
                >
                  <h2>Nieobecność</h2>
                  <ul class="list-unstyled">
                    <li>
                      <label>Od:</label>
                      {{ (user.absences && user.absences.length > 0) ? user.absences[0]['startAt'] : '' }}
                    </li>
                    <li>
                      <label>Do:</label>
                      {{ (user.absences && user.absences.length > 0) ? user.absences[0]['endAt'] : '' }}
                    </li>
                    <li>
                      <label>Osoba zastępująca:</label>
                      {{ substituteFromVuex }}
                    </li>
                    <li>
                      <label>Przesyłaj pocztę zastępującemu:</label>
                      {{ (user.absences && user.absences.length > 0) ? (user.absences[0]['redirectToSubstitute'] ? 'Tak': 'Nie') : '' }}
                    </li>
                    <li>
                      <label>Wyślij kopie do mnie:</label>
                      {{ (user.absences && user.absences.length > 0) ? (user.absences[0]['sendCopyToAbsent'] ? 'Tak': 'Nie') : '' }}
                    </li>
                  </ul>
                </div>
                <Coins v-if="$auth.isAllowed({service: 'extshp', action: 'get_user_coins'})"></Coins>
                <div class="details-header--details-custom details-header--status">
                  <ul class="list-unstyled">
                    <li>
                      <span
                        class="status-tag"
                        :class="statusClasses[user.status]"
                      >{{ $t(userStatuses[user.status]) }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="box box-default box-solid box-component">
            <div class="box-body">
              <h3 class="box-title">{{$t('pages.user.details.assignedRoles')}}</h3>
              <table class="table-light">
                <thead>
                  <tr>
                    <th class="table-light--label">{{$t('pages.user.details.client')}}</th>
                    <th class="table-light--label">{{$t('pages.user.details.role')}}</th>
                    <th class="table-light--desc">Opis</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="app in apps">
                    <template v-if="userRolesLoaded[app.name]">
                      <template v-for="(userRole, roleIndex) in userRoles[app.name]">
                        <tr
                          :key="`${app.name}_${roleIndex}`"
                          :class="{'major-tr': roleIndex === 0}"
                        >
                          <td
                            v-if="roleIndex === 0"
                            :rowspan="userRoles[app.name].length"
                          >{{ app.label }}</td>
                          <td>{{ userRole.label }}</td>
                          <td>{{ userRole.description }}</td>
                        </tr>
                      </template>
                    </template>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Modals -->
    <Vat
      v-if="vatEditionVisible"
      :show="vatEditionVisible"
      :form-data="$store.state.settlementsState.vatModel"
      @close="vatEditionVisible = false"
      @success="vatEditionVisible = false"
    />
    <user-data-edition-modal
      :show="userDataEditionVisible"
      :form-data="user"
      @close="userDataEditionVisible = false"
      @success="userEdited => user = userEdited"
    />
    <!--<email-edition-modal-->
    <!--:show="emailEditionVisible"-->
    <!--@close="emailEditionVisible = false"-->
    <!--@success="emailEdited => user.email = emailEdited"-->
    <!--&gt;-->
    <!--</email-edition-modal>-->
    <password-edition-modal
      v-if="dataLoaded"
      :show="passwordEditionVisible"
      :roles="user.roles"
      @close="passwordEditionVisible = false"
    />
    <unit-edit-modal
      :show="unitEditionVisible"
      :form-data="units"
      @close="unitEditionVisible = false"
      @success="unitsEdited => {
        user.units = Object.values(unitsEdited).filter(element => element.value)
        units = unitsEdited
      }"
    />
    <contractor-edit-modal
      :show="contractorEditionVisible"
      :form-data="user.contractor"
      @close="contractorEditionVisible = false"
      @success="updateContractor"
      @reloadAvatarImage="showImage"
    />
    <absence-edit-modal
      v-if="absenceVisible && absenceEditionVisible"
      :user="user"
      :show="absenceEditionVisible"
      :form-data="user.absences[0]"
      @close="absenceEditionVisible = false"
      @success="absenceEdited => user.absences[0] = absenceEdited"
    />
    <LanguageEdit
      :show="languageEditVisible"
      :form-data="user"
      @success="() => languageChangedSuccesful()"
      @close="() => languageEditVisible = false"
    ></LanguageEdit>
    <IpFilters
      v-if="ipFiltersModalVisible"
      :show="ipFiltersModalVisible"
      :form-data="ips"
      :service="'core'"
      @close="ipFiltersModalVisible = false"
      @success="ipFiltersSetSuccesfully"
    ></IpFilters>
    <UserAdditionalDataEdition
      :show="userAdditionalDataEditionVisible"
      :form-data="user"
      @close="userAdditionalDataEditionVisible = false"
      @success="updateUserAdditionalData"
    ></UserAdditionalDataEdition>
    <PricelistDataEdition
      v-if="pricelistDataEditionVisible"
      :show="pricelistDataEditionVisible"
      :form-data="user"
      @close="pricelistDataEditionVisible = false"
    ></PricelistDataEdition>
    <PresenceModal
      v-if="presenceModalVisible"
      :show="presenceModalVisible"
      @close="presenceModalVisible = false"
    >
    </PresenceModal>
  </div>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import api from '../../../../../api'
import DynamicBreadcrumb from '../../../share/mixins/DynamicBreadcrumb'
import PasswordEditionModal from './modal/PasswordEdition'
import UserDataEditionModal from './modal/UserDataEdition'
import AbsenceEditModal from './modal/AbsenceEdition'
import UnitEditModal from '../../../share/unit/modal/Edit'
import ContractorEditModal from '../../../share/contractor/modal/Edit'
import Page from '../../../../share/content/Page'
import moment from 'moment'
import WebStorage from '../../../../share/WebStorage'
import Vat from './modal/Vat'
import {mapGetters} from 'vuex'
import financeMixin from './mixins/financeMixin'
import Coins from './coins/Coins'
import LanguageEdit from './modal/LanguageEdit'
import ErrorNotify from '../../../../share/mixins/ErrorNotify'
import IpFilters from './modal/IpFilters'
import Ips from '../../../share/admin/ips/Ips'
import UserAdditionalDataEdition from './modal/UserAdditionalDataEdition'
import PricelistDataEdition from './modal/PricelistDataEdition'
import PresenceModal from '@/components/bundles/share/modal/presenceModal/PresenceModal.vue'

Vue.filter("status", value => {
  switch (value) {
    case 0:
      return "pages.user.details.accouontNotActive";
    case 1:
      return "pages.user.details.accouontActivationInterrupted";
    case 2:
      return "pages.user.details.accouontactive";
  }
});

Vue.filter("statusColor", value => {
  switch (value) {
    case 0:
      return "red";
    case 1:
      return "blue";
    case 2:
      return "green";
  }
});

export default {
  components: {
    PresenceModal,
    PricelistDataEdition,
    UserAdditionalDataEdition,
    Ips,
    IpFilters,
    LanguageEdit,
    Coins,
    Vat,
    PasswordEditionModal,
    UserDataEditionModal,
    UnitEditModal,
    ContractorEditModal,
    AbsenceEditModal,
  },
  mixins: [DynamicBreadcrumb, Page, financeMixin, ErrorNotify],
  data() {
    return {
      events: {
        editUserData: "core:user:editUserData",
        editPricelist: "finance:user:editPricelist",
        editUserAdditionalData: "core:user:editUserAdditionalData",
        editPassword: "core:user:editPassword",
        resetPassword: "core:user:resetPassword",
        activateUser: "core:user:activateUser",
        activateUserToggle: "core:user:activateUserToggle",
        editUnit: "core:user:editUnit",
        editContractor: "core:user:editContractor",
        editAbsence: "core:user:editAbsence",
        editRoles: "core:user:editRoles",
        editVat: "finance:user:editVat",
        editLanguage: "core:user:editLanguage",
        editUserIps: "core:user:editUserIps",
        editUserPresence: "core:user:editUserPresence"
      },
      headerTitle: { title: "Użytkownicy", description: "Szczegóły" },
      userDataEditionVisible: false,
      userAdditionalDataEditionVisible: false,
      pricelistDataEditionVisible: false,
      emailEditionVisible: false,
      passwordEditionVisible: false,
      unitEditionVisible: false,
      contractorEditionVisible: false,
      absenceEditionVisible: false,
      actionEditName: "user_edit",
      actionEditEmailName: "user_edit_email",
      actionEditPasswordName: "user_edit_password",
      actionEditUnitName: "user_edit_unit",
      actionEditContractorName: "user_edit_contractor",
      // urlActions: {
      //   getContent: {method: 'get', url: `users/${this.$route.params.uuid}`},
      //   activateUser: {method: 'put', url: `users/${this.$route.params.uuid}/activation`},
      //   resetPassword: {method: 'post', url: 'security/password-reset-link'}
      // },
      user: {
        forename: "",
        surname: "",
        status: "",
        email: "",
        phone: "",
        description: "",
        units: [],
        contractor: {},
        uuid: "",
        absences: [],
        workingSaturday: false,
        isAttachmentPassword: false
      },
      userStatuses: [
      "pages.user.details.accouontNotActive",
      "pages.user.details.accouontActivationInterrupted",
      "pages.user.details.accouontactive"
      ],
      units: [],
      allUnits: [],
      dataLoaded: false,
      apps: [],
      userRoles: {},
      userRolesLoaded: {},
      securityActions: null,
      statusClasses: ["error", "info", "success"],
      languageEditVisible: false,
      ipFiltersModalVisible: false,
      presenceModalVisible: false,
      ips: [],
      contractorIps: []
    };
  },
  computed: {
    ...mapGetters(["getVatToShow"]),
    allIps() {
      return this.ips.concat(this.contractorIps);
    },
    substituteFromVuex: {
      cache: false,
      get() {
        if (
          _.has(this.user.absences, [0, "substitute"]) &&
          this.$store.state.base.substitutes[this.user.absences[0].substitute]
        ) {
          return this.$store.state.base.substitutes[this.user.absences[0].substitute].fullname;
        }

        return "";
      },
      set(newVal) {
        return newVal;
      }
    },
    urlActions: {
      get() {
        return {
          getContent: {
            method: "get",
            url: `users/${this.$route.params.uuid}`
          },
          activateUser: {
            method: "put",
            url: `users/${this.$route.params.uuid}/activation`
          },
          activateUserToggle: {
            method: "put",
            url: `users/${this.$route.params.uuid}/toggle-active`
          },
          resetPassword: { method: "post", url: "security/password-reset-link" }
        };
      },
      set(newVal) {
        return newVal;
      }
    },
    absenceVisible() {
      if (this.securityActions && this.securityActions.hasOwnProperty("core")) {
        return this.securityActions.core.includes("put_user_absence");
      } else {
        return false;
      }
    }
  },
  watch: {
    "$route.params.uuid"() {
      this.loadContent();
    }
  },
  mounted() {
    this.getSecurityActions();
    this.$events.on(this.events.editUserData, this.editUserData);
    this.$events.on(this.events.editPricelist, this.editPricelist);
    this.$events.on(
      this.events.editUserAdditionalData,
      this.editUserAdditionalData
    );
    this.$events.on(this.events.editPassword, this.editPassword);
    this.$events.on(this.events.editLanguage, this.editLanguage);
    this.$events.on(this.events.resetPassword, () => {
      let params = { email: this.user.email };
      this.actionRun(
        this.urlActions.resetPassword,
        params,
        "Przypomnienie hasła wysłane na adres mailowy"
      );
    });
    this.$events.on(this.events.activateUser, () => {
      this.actionRun(
        this.urlActions.activateUser,
        null,
        "Link aktywacyjny wysłany na adres mailowy"
      );
    });
    this.$events.on(this.events.activateUserToggle, () => {
      this.toggleUserActivity(this.urlActions.activateUserToggle);
    });
    this.$events.on(this.events.editUnit, this.editUnit);
    this.$events.on(this.events.editContractor, this.editContractor);
    this.$events.on(this.events.editAbsence, this.editAbsence);
    this.$events.on(this.events.editRoles, this.editRoles);
    this.$events.on(this.events.editVat, this.editVat);
    this.$events.on(this.events.editUserIps, this.editUserIps);
    this.$events.on(this.events.editUserPresence, this.editUserPresence);
    this.loadIps();
    this.$nextTick(() => {
      this.loadContent();
    });
  },
  methods: {
    languageChangedSuccesful() {
      this.languageEditVisible = false
      this.loadContent();
    },
    editLanguage() {
      this.languageEditVisible = true;
    },
    getSecurityActions() {
      this.securityActions = WebStorage.getSecurityActions();
    },
    getUserUnitsWithValues() {
      if (this.user.units) {
        this.units = this.allUnits.map(option => {
          return {
            id: option.id,
            label: option.label,
            value: !!this.user.units.find(element => element.id === option.id)
          };
        });
      }
    },
    loadUnits() {
      api
        .request("core", "get", "/units")
        .then(response => {
          this.allUnits = response.data;
          this.getUserUnitsWithValues();
        })
        .catch(error => {
          this.errorNotify(error);
        });
    },
    initLoaded() {
      let result = {};
      for (let c = 0; c < this.apps.length; c++) {
        result[this.apps[c].name] = false;
      }
      return result;
    },
    editUserData() {
      this.userDataEditionVisible = true;
    },
    editUserAdditionalData() {
      this.userAdditionalDataEditionVisible = true;
    },
    editPricelist() {
      this.pricelistDataEditionVisible = true;
    },
    updateUserAdditionalData(userAdditionalDataEdited) {
      this.user = {...this.user, ...userAdditionalDataEdited};
    },
    editPassword() {
      this.passwordEditionVisible = true;
    },
    editUnit() {
      this.loadUnits();
      this.unitEditionVisible = true;
    },
    editRoles() {
      this.$router.push({ name: "admin_user_details_roles_edition" });
    },
    editContractor() {
      this.contractorEditionVisible = true;
    },
    editAbsence() {
      this.absenceEditionVisible = true;
    },
    editUserIps() {
      this.ipFiltersModalVisible = true;
    },
    editUserPresence() {
      this.presenceModalVisible = true
    },
    updateContractor(event) {
      this.user.contractor = event;
    },
    ipFiltersSetSuccesfully() {
      this.ipFiltersModalVisible = false;
      this.loadIps();
    },
    presenceSetSuccesfully() {
      this.presenceModalVisible = false
    },
    cancelPresence () {
      this.presenceModalVisible = false
    },
    confirmPresence () {
      let user = this.$store.state.base.user
      api.request('core', 'put', `/users/${user.uuid}/presence`)
        .then((response) => {
          this.presenceModalVisible = false
          this.$notify({
            type: 'success',
            text: 'Obecność potwierdzona'
          })
        })
        .catch(() => {
          this.toggleLoading()
          this.presenceModalVisible = false
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można potwierdzić obecności'
          })
        })
    },
    loadIps() {
      if (
        this.$auth.isAllowed({ service: "core", action: "get_user_access_ips" })
      ) {
        this.toggleLoading();

        api
          .request(
            "core",
            "get",
            `/users/${this.$route.params.uuid}/access-ips`
          )
          .then(response => {
            this.toggleLoading();
            this.ips = response.data.userIps.map(ip => ip.address.split("."));
            this.contractorIps = response.data.contractorIps.map(ip =>
              ip.address.split(".")
            );
          })
          .catch(() => {
            this.toggleLoading();
          });
      }
    },
    loadContent() {
      this.toggleLoading();

      api
        .request(
          "core",
          this.urlActions.getContent.method,
          this.urlActions.getContent.url
        )
        .then(response => {
          this.toggleLoading();
          let user = response.data.user;
          this.user = user;
          this.loadFinanceContent();

          // dynamic breadcrumb labels
          this.addDynamicBreadcrumbLabel(
            this.$route.name,
            this.user.forename + " " + this.user.surname
          );

          if (_.has(this.user, "absences[0].substitute.uuid")) {
            this.user.absences[0].substitute = this.user.absences[0].substitute.uuid;
          }
          if (_.has(this.user, "absences[0].startAt" && "absences[0].endAt")) {
            this.user.absences[0].startAt = moment(
              this.user.absences[0].startAt
            ).format("YYYY-MM-DD");
            this.user.absences[0].endAt = moment(
              this.user.absences[0].endAt
            ).format("YYYY-MM-DD");
          }
          this.apps = response.data.apps;
          for (let c = 0; c < this.apps.length; c++) {
            this.userRoles[this.apps[c].name] = [];
          }
          this.userRolesLoaded = this.initLoaded();
          this.dataLoaded = true;
          this.showImage(response.data.avatarImage);
          // TODO: Set email to user in store, set new token

          for (let c = 0; c < this.apps.length; c++) {
            api
              .request(
                this.apps[c].name,
                "get",
                `/users/${this.user.uuid}/roles`
              )
              .then(response => {
                let roles = response.data;
                let appName = response.headers["x-app-name"];
                for (let d = 0; d < roles.length; d++) {
                  this.userRoles[appName].push(roles[d]);
                }
                this.userRolesLoaded[appName] = true;
              })
              .catch(() => {
                this.toggleLoading();
              });
          }
        })
        .catch(() => {
          this.toggleLoading();
        });
    },
    loadFinanceContent() {
      api
        .request("finance", "get", `users/${this.$route.params.uuid}`)
        .then(response => {
          let user = _.cloneDeep(this.user);
          user.workingSaturday = response.data.workingSaturday;
          this.user = user;
        })
        .catch(error => {
          this.toggleLoading();
        });
    },
    showImage(file) {
      let img = document.getElementById("avatarImage");
      img.src = `data:image-png;base64,${file}`;
    },
    actionRun(
      urlAction,
      params = null,
      notifySuccesssText = "Dane zapisane poprawnie"
    ) {
      api
        .request("core", urlAction.method, urlAction.url, params)
        .then(() => {
          this.toggleLoading();
          this.$notify({
            type: "success",
            text: notifySuccesssText
          });
        })
        .catch(() => {
          this.$notify({
            type: "error",
            text: "Wystąpił błąd"
          });
        });
    },
    toggleUserActivity(urlAction) {
      api
        .request("core", urlAction.method, urlAction.url)
        .then(response => {
          this.toggleLoading();
          this.loadContent();
          this.$notify({
            type: "success",
            text: "Status aktywności uzytkownika zmieniony poprawnie"
          });
        })
        .catch(e => {
          this.errorNotify(e);
        });
    }
  }
};
</script>

<style scoped>
.label {
  display: inline-block;
  margin-bottom: 0.3em;
  vertical-align: middle;
}
.details-header--details-custom {
  flex: 2 1 17%;
}
</style>
