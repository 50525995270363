<template>
  <form>
    <template
      v-for="field in schema"
    >
      <input-type
        v-if="!field.hasOwnProperty('hidden') || (field.hasOwnProperty('hidden') && !field.hidden)"
        :key="field.name"
        v-model="value[field.name]"
        v-validate="{ rules: field.rules }"
        :data-vv-value-path="'value'"
        :data-vv-as="field.label2 ? field.label2 : field.label"
        :error-message="vErrors.first(field.name)"
        :label="field.label"
        :name="field.name"
        :required="field.rules.required"
      />
    </template>
    <div class="">
      <div class="user-details-checkbox-container">
        <input id="working-saturday-checkbox" type="checkbox" v-model="value['workingSaturday']">
        <label for="working-saturday-checkbox"> Pracująca sobota</label>
      </div>
      <div class="user-details-checkbox-container">
        <input id="attachment-password-requirement-checkbox" type="checkbox" v-model="value['isAttachmentPassword']">
        <label for="attachment-password-requirement-checkbox"> Załączniki wymagają hasła</label>
      </div>
    </div>

  </form>
</template>

<script>
  import FormModalValidation from '../../../../../share/form/FormModalValidation'
  import InputType from '../../../../../share/form/type/InputType'

  export default {
    components: {
      InputType
    },
    mixins: [
      FormModalValidation
    ],
    props: {
      value: {}
    },
    data () {
      return {
        schema: [
          { name: 'description', label: 'Opis', rules: { required: false, max: 255 }, hidden: !this.$store.state.base.user.roles.includes('ROLE_SUPER_ADMIN') },
          { name: 'entitlementNumber', label: 'Numer uprawnień', rules: { required: false, max: 255 } }
        ]
      }
    }
  }
</script>
<style>
  .user-details-checkbox-container {

  }
</style>
