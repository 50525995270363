<template>
  <base-form-modal
    :show="show"
    :title="title"
    :ok-text="okText"
    :cancel-text="cancelText"
    :remember-model-after-close="true"

    @show="open"
    @close="$emit('close')"
    @beforeSubmit="function (bool) {beforeSubmitTrigger = bool}"
  >
    <form-modal
      ref="form"
      v-model="model"
      :is-before-submit="beforeSubmitTrigger"
      :service="service"
    />
  </base-form-modal>
</template>
<script>
  import FormModalMixin from '../../../../../share/modal/FormModalMixin'
  import FormModal from '../form/Vat'
  import api from '../../../../../../api'
  import _ from 'lodash'
  import {mapActions} from 'vuex'

  export default {
    name: 'Vat',
    components: {
      FormModal
    },
    mixins: [
      FormModalMixin
    ],
    data () {
      return {
        model: {},
        title: 'Edycja sposobu rozliczenia',
        okText: 'Zmień'
      }
    },
    mounted () {
      this.model = _.cloneDeep(this.formData)
    },
    methods: {
      ...mapActions([
        'getUsersVat'

      ]),
      async submitForm () {
        try {
          await api.request('finance', 'put', `users/${this.$route.params.uuid}`, this.createDataToSend())
          this.toggleLoading()

          this.$emit('success')
          this.$emit('close')
          this.getUsersVat({userUuid: this.$route.params.uuid})
          this.$notify({
            type: 'success',
            text: 'Zaktualizowano sposób rozliczenia'
          })
        } catch (e) {
          this.toggleLoading()
          this.errorNotify(e)
          this.$emit('close')
        }
      },
      createDataToSend () {
        return {
          vat: this.model.selectedVat
        }
      }
    }
  }
</script>
