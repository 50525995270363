<template>
  <form>
    <input-type
      v-for="field in schema"
      :key="field.name"
      v-model="value[field.name]"
      v-validate="{ rules: field.rules }"
      :data-vv-value-path="'value'"
      :data-vv-as="field.label2 ? field.label2 : field.label"
      :error-message="vErrors.first(field.name)"
      :label="field.label"
      :name="field.name"
      :required="field.rules.required"
      :type="'password'"
    />
  </form>
</template>

<script>
import { Validator } from 'vee-validate'
import FormModalValidation from '../../../../../share/form/FormModalValidation'
import InputType from '../../../../../share/form/type/InputType'

export default {
  components: {
    InputType
  },
  mixins: [
    FormModalValidation
  ],
  props: {
    value: {}
  },
  data () {
    return {
      // comes from parent modal injection
      roles: [],
      schemaUser: [
        { name: 'oldPassword', label:  this.$t('login.currentPassword'), rules: { required: true, confirm_old_password: true } },
        { name: 'password', label: this.$t('login.password'), rules: { required: true, password_form: true, min: 8, different_password: true } },
        { name: 'password2', label: this.$t('login.repeatPassword'), rules: { required: true, repeated_password: true } }
      ],
      schemaAdmin: [
        { name: 'password', label: this.$t('login.password'), rules: { required: true, password_form: true, min: 8, different_password: true } },
        { name: 'password2', label: this.$t('login.repeatPassword'), rules: { required: true, repeated_password: true } }
      ]
    }
  },
  computed: {
    schema: function () {
      if (this.$store.state.base.user.roles.includes('ROLE_SUPER_ADMIN')) {
        return this.schemaAdmin
      } else {
        return this.schemaUser
      }
    },
  },
  watch: {
    '$store.state.base.isValidCurrentPassword': function() {
      if (this.$store.state.base.isValidCurrentPassword) {
        this.vErrors.items.push({
          id: "9",
          vmId: this.vErrors.vmId,
          field: "oldPassword",
          msg: "Bieżące hasło jest niepoprawne",
          rule: "confirm_old_password",
          scope: null
        })
      }
    }
  },
  created () {
    Validator.remove('confirm_old_password')
    Validator.extend('confirm_old_password', {
      getMessage: () => this.$t('login.requiredConfirmPasswordValidator'),
      validate: value => value
    })
    Validator.remove('repeated_password')
    Validator.extend('repeated_password', {
      getMessage: () => this.$t('login.repeatedPasswordValidator'),
      validate: value => value === this.value.password
    })
    Validator.remove('password_form')
    Validator.extend('password_form', {
      getMessage: () => this.$t('login.requiredPassworddValidator'),
      validate: value => (/[A-Z]+/.test(value) && /[0-9]+/.test(value) && /[!@#$%^&*()_+={}\\[\]:;"'<,>.?/-]+/.test(value))
    })
    Validator.remove('different_password')
    Validator.extend('different_password', {
      getMessage: () => this.$t('login.passwordDifferentThanCurrent'),
      validate: value => value !== this.value.oldPassword
    })
  }
}
</script>
