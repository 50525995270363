<template>
  <form>
    <input-type
      type="number"
      v-model="value.amount"
      :required="true"
      label="Ilość wolnych Żetonów"
      name="amount"
      data-vv-as="Ilość wolnych Żetonów"
      id="amount"
      v-validate="{ rules: { required: true } }"
      :error-message="vErrors.first('amount')"
    ></input-type>
  </form>
</template>

<script>
  import FormModalValidation from '../../../../../share/form/FormModalValidation'
  import InputType from '../../../../../share/form/type/InputType'

  export default {
    components: {
      InputType
    },
    mixins: [
      FormModalValidation
    ],
    props: {
      value: {}
    }
  }
</script>
