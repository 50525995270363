<template>
  <div class="details-header--details details-header--details-custom">
    <h2>Monety</h2>
    <ul class="list-unstyled">
      <li class="user-coin user-coin-free"><label>Żetony wolne: </label>
        <span @click="changeCoins" :class="{'free-coins-count': $auth.isAllowed({service: 'extshp', action: 'put_user_coins'})}"> {{ freeCoins }}</span>
          <EditCoinAmountModal
           :show="coinsEditionVisible"
           @success="getCoinsData"
           @close="coinsEditionVisible = false"
          >
          </EditCoinAmountModal>
      </li>
      <li class="user-coin user-coin-used">
        <label>Żetony w użyciu: </label> {{ usedCoins }}
      </li>
    </ul>
  </div>
</template>

<script>
  import api from '../../../../../../api'
  import EditCoinAmountModal from './EditCoinAmountModal'

  export default {
    name: 'Coins',
    components: {EditCoinAmountModal},
    data () {
      return {
        freeCoins: '',
        usedCoins: '',
        amount: 0,
        coinsEditionVisible: false
      }
    },
    mounted () {
      this.getCoinsData()
      this.$events.on('extshp:user:editCoins', () => this.coinsEditionVisible = true)
    },
    methods: {
      getCoinsData () {
        api.request('extshp', 'get', `/users/${this.$route.params.uuid}/coins`)
          .then((response) => {
            this.freeCoins = response.data.free
            this.amount = this.freeCoins
            this.usedCoins = response.data.used
          })
          .catch((error) => {
            this.errorNotify(error)
          })
      },
      changeCoins () {
        if (this.$auth.isAllowed({service: 'extshp', action: 'put_user_coins'})) {
          this.coinsEditionVisible = true
        }
      },
      addCoins (e) {
        e.preventDefault()
        let dataToSend = { amount: this.amount }

        api.request('extshp', 'put', `/users/${this.$route.params.uuid}/coins`, dataToSend)
          .then((response) => {
            this.freeCoins = this.amount
            this.coinsEditionVisible = false
            this.getCoinsData()
            this.$notify({
              type: 'success',
              text: 'Pomyślnie zmieniono ilość Żetonów'
            })
          })
          .catch((error) => {
            this.errorNotify(error)
          })
      },
    }
  }
</script>

<style scoped>
  .user-coins-edit {
    display: flex;
    flex-direction: column;
  }
  .free-coins-count {
    cursor: pointer;
    color: #42A5FF;
  }
  .user-coin {
    white-space: nowrap;
  }
  .details-header--details-custom {
    flex: 2 1 17%;
  }
</style>
