<script>
import FormModalMixin from '../../../../share/modal/FormModalMixin'
import FormModal from '../form/Edit'
import api from '../../../../../api'

export default {
  components: {
    FormModal
  },
  mixins: [
    FormModalMixin
  ],
  props: {
    units: {},
    formData: { type: Array, default () { return [] } },
    mode: { type: String, default: 'edit' },
    action: { type: Object, default () { return { edit: 'users' } } }
  },
  data () {
    return {
      title: 'Edycja jednostki',
      okText: 'Zmień',
      allUnits: {}
    }
  },
  methods: {
    submitForm () {
      api.request('core', 'put', `/${this.action[this.mode]}/${this.$route.params.uuid}/units`, this.createDataToSend())
        .then(() => {
          this.toggleLoading()
          this.$emit('success', this.model)
          this.$emit('close')
          this.$notify({
            type: 'success',
            text: 'Zaktualizowano jednostki'
          })
        })
        .catch((error) => {
          this.toggleLoading()
          this.errorNotify(error)
        })
    },
    createDataToSend () {
      return { 'units': Object.values(this.model).filter(element => element.value).map(element => element.id) }
    }
  }
}
</script>
