<script>
import FormModalMixin from '../../../../share/modal/FormModalMixin'
import FormModal from '../form/Edit'
import api from '../../../../../api'

export default {
  components: {
    FormModal
  },
  mixins: [
    FormModalMixin
  ],
  data () {
    return {
      events: {
        modalContractor: 'core:user:modalContractor'
      },
      title: 'Edycja kontrahenta',
      okText: 'Zmień',
      model: {}
    }
  },
  created () {
    this.listenForContractorSelectChange()

    this.$on('close', () => {
      this.$refs.form.contractorSelectHasBeenChanged = false
    })
  },
  methods: {
    submitForm () {
      api.request('core', 'put', `/users/${this.$route.params.uuid}/contractor`, this.createDataToSend())
        .then(() => {
          this.loadContractor()
          this.toggleLoading()
          this.$emit('success', this.model)
          this.$emit('close')
          this.$notify({
            type: 'success',
            text: 'Zaktualizowano kontrahenta'
          })
        })
        .catch((error) => {
          this.toggleLoading()
          this.errorNotify(error)
        })
    },
    createDataToSend () {
      return { contractor: this.model.id }
    },
    loadContractor () {
      api.request('core', 'get', `/contractors/${this.model.id}`)
        .then((response) => {
          this.$emit('reloadAvatarImage', response.data.avatarImage)
        })
        .catch((error) => {
          this.errorNotify(error)
        })
    },
    listenForContractorSelectChange () {
      this.$events.on(this.events.modalContractor, (selectedContractor) => {
        this.model = selectedContractor
      })
    }
  }
}
</script>
