<script>
import FormModalMixin from '../../../../../share/modal/FormModalMixin'
import FormModal from '../form/PasswordEdition'
import api from '../../../../../../api'

export default {
  components: {
    FormModal
  },
  mixins: [
    FormModalMixin
  ],
  props: {
    roles: { type: Array, required: true }
  },
  data () {
    return {
      title: this.$t('pages.user.details.editPasswordTitle'),
      okText: this.$t('pages.user.details.editPasswordOkButton')
    }
  },
  mounted () {
    this.$refs.form.roles = this.roles
  },
  methods: {
    submitForm () {
      this.$store.commit('SET_VALID_CURRENT_PASSWORD', false)

      api.request('core', 'put', `users/${this.$route.params.uuid}/password`, this.createDataToSend())
        .then(() => {
          this.toggleLoading()

          this.$emit('close')
          this.$notify({
            type: 'success',
            text: this.$t('pages.user.details.passwordUpdated')
          })
        })
        .catch(() => {
          this.toggleLoading()
          this.$store.commit('SET_VALID_CURRENT_PASSWORD', true)
        })
    },
    createDataToSend () {
      if (this.$store.state.base.user.roles.includes('ROLE_SUPER_ADMIN')) {
        return {
          password: this.model.password
        }
      } else {
        return {
          oldPassword: this.model.oldPassword,
          password: this.model.password
        }
      }
    }
  }
}
</script>
