<template>
  <base-form-modal
    :show="show"
    :title="title"
    :ok-text="okText"
    :cancel-text="cancelText"
    :remember-model-after-close="true"

    @show="open"
    @close="$emit('close')"
    @beforeSubmit="function (bool) {beforeSubmitTrigger = bool}"
  >
    <form-modal
      ref="form"
      v-model="model"
      :is-before-submit="beforeSubmitTrigger"
      :service="service"
    />
  </base-form-modal>
</template>

<script>
  import FormModalMixin from '../../../../../share/modal/FormModalMixin';
  import FormModal from '../form/PricelistDataEdition';
  import api from '../../../../../../api';

  export default {
    name: 'Pricelist',
    components: {
      FormModal
    },
    mixins: [
      FormModalMixin
    ],
    data () {
      return {
        model: {},
        title: 'Edycja cennika',
        okText: 'Zmień'
      }
    },
    created() {
      this.getPricelistAmount()
    },
    methods: {
      async submitForm () {
        this.toggleLoading()
        this.addOrUpdatePricelist()
      },
      async addOrUpdatePricelist() {
        try {
          await api.request('finance', 'put', `/appraiser-price-list`, this.createDataToSend())
          this.toggleLoading()

          this.$emit('success')
          this.$emit('close')

          this.$notify({
            type: 'success',
            text: 'Zaktualizowano cennik rzeczoznawcy'
          })
        } catch (e) {
          this.toggleLoading()
          this.errorNotify(e)
          this.$emit('close')
        }
      },
      parseDecimalValue(stringVal) {
        return typeof stringVal === 'string' ? Number(stringVal.replace(',', '.')) : stringVal
      },
      createDataToSend () {
        const {residentialFinished, residentialInProgress, buildingFinished, buildingInProgress, plot} = this.model

        return {
          service: "intmil",
          user: `${this.$route.params.uuid}`,
          prices: {
            residentialFinished: this.parseDecimalValue(residentialFinished),
            residentialInProgress: this.parseDecimalValue(residentialInProgress),
            buildingFinished: this.parseDecimalValue(buildingFinished),
            buildingInProgress: this.parseDecimalValue(buildingInProgress),
            plot: this.parseDecimalValue(plot)
          }
        }
      },
      async getPricelistAmount () {
        try {
          const response = api.request('finance', 'get', `/appraiser-price-list?service=intmil&uuid=${this.$route.params.uuid}`)
          const {data} = await response

          this.model = data
          return data

        } catch (e) {
          this.errorNotify(e)
        }
      }
    }
  }
</script>
