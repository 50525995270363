<template>
  <form>
    <div
      class="modal-body form-group required-field"
      :class="[{ 'has-error': vErrors.first('vatSelect'), 'has-success' : !vErrors.first('vatSelect') }]"
    >
      <label>Sposób rozliczenia:</label>
      <select
        v-model="value['selectedVat']"
        name="vatSelect"
        class="form-control"
      >
        <option
          v-for="(option, index) in $store.state.settlementsState.vatOptions"
          :key="index"
          :value="option.id"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
  </form>
</template>

<script>
  import FormModalValidation from '../../../../../share/form/FormModalValidation'

  export default {
    mixins: [
      FormModalValidation
    ],
    props: {
      value: {type: Object, required: true}
    },
    data () {
      return {
        selectDisabled: false,
        vatOptions: []
      }
    },
    mounted () {
    },
    methods: {

    }
  }
</script>
