<script>
  import FormModalMixin from '../../../../../share/modal/FormModalMixin'
  import FormModal from '../form/LanguageEdit'
  import api from '../../../../../../api'

  export default {
    components: {
      FormModal
    },
    mixins: [
      FormModalMixin
    ],
    data () {
      return {
        title: 'Edycja języka kontrahenta',
        okText: 'Zmień'
      }
    },
    methods: {
      submitForm () {
        api.request('core', 'put', `/users/${this.$route.params.uuid}/locale`, this.createDataToSend())
          .then(() => {
            this.toggleLoading()

            this.$emit('success', this.model)
            this.$emit('close')
            this.$notify({
              type: 'success',
              text: 'Zaktualizowano język użytkownika'
            })
          })
          .catch((error) => {
            this.toggleLoading()
            this.errorNotify(error)
          })
      },
      createDataToSend () {
        return {
          locale: this.model.locale
        }
      }
    }
  }
</script>
